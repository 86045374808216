<template>
	<div id="app">
		<transition name="tomm-none" mode="out-in">
			<router-view />
		</transition>
	</div>
</template>
<style>
.tomm-slide-enter-active,
.tomm-slide-leave-active {
	transition: transform 1s;
	overflow: hidden;
}
.tomm-slide-enter {
	transform: translateY(100%);
}
.tomm-slide-leave-to {
	transform: translateY(-100%);
}
/* #container {
	padding: 20px 0;
} */
/* body {
	padding: 1px 0 !important;
	transform: translateY(-1px);
} */
</style>
<script>
// import debounce from 'lodash.debounce';
    export default {

	data() {
		return {
			transition: 'slide',
		}
	},
	created() {
		this.$router.beforeEach((to, from, next) => {
			this.transition = 'slide-up';
			next();
		});
    // let lastPos = window.scrollY
    // let ticking = false
    // window.currentSection = 0

		// window.addEventListener('scroll', e => {
    //   if (!ticking) {
    //     window.requestAnimationFrame(() => {
    //       window.currentSection = this.scrollSetup(e, lastPos, window.currentSection)
    //     })
    //     ticking = true
    //     setTimeout(() => {
    //       ticking = false
    //       lastPos = window.scrollY
    //     }, 500)
    //   }
    // });
	},
	destroyed() {
		//window.removeEventListener('mousewheel', this.handleScroll);
	},
        methods: {
                
                scrollSetup(e, lastPos, currentSection) {
                  e.preventDefault()
                  let newSection = currentSection
                  const orderKeys = ['!home', '!principles', '!how-it-works', '!tools', '!training', '!pricing-b'];
                 
                  if (window.scrollY > lastPos) {
                    e.preventDefault()
                    newSection++
                  } else if (window.scrollY < lastPos) {
                    e.preventDefault()
                    newSection--
                  } else {
                    return null
                  }

                  if (newSection <= 0) newSection = 0
                  if (newSection >= orderKeys.length - 1) newSection = orderKeys.length - 1

                  const element = document.getElementById(`${orderKeys[newSection]}`)
                  const topPos = element.getBoundingClientRect().top

                  window.scrollTo(0, topPos)
                  return newSection
                }
	},
}
</script>
