<template>
  <div id="container">
      <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Base",
  components: {},
  props: {},
  data: function () {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {}
};
</script>
<style scoped>
</style>

